import React from 'react';

const Partnerships = () => {
  return (
    <div>
      <div>
        <h1 style={{ color: '#fff' }}>Partnerships</h1>
      </div>
    </div>
  );
};

export default Partnerships;
